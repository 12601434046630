/*global hoverintent, jQuery */
(function(w,$) {
"use strict";

/**
 * 	Global app methods
 *
 * @requires jQuery
 */
var App = {

	/**
	 * 	Store DOM elements
	 */
	getEl : function() {
		return {
			body : $("body"),
			html : $("html"),
			header : $("#page-header"),
			navMenu : $(".nav-button"),
			navigation : $("#page-header .navigation ul li")
		};
	},

	/**
	 * 	Initialize methods
	 */
	init : function() {
		try {
			this.el = this.getEl();

			this.events(this.el);
			this.removeJSIdentifier(this.el.html);
			this.initDropdowns(this.el.navigation);
			this.initStickyHeader(this.el.header);
			this.checkDropdownPos();
		} catch(exception) {
			console.warn(exception);
		}
	},

	/**
	 * 	Bind events
	 *
	 *	@param {object} el - element object
	 */
	events : function(el) {
		// @fire App#handleToggleNav:click
		el.navMenu.off("click.odc").on("click.odc", $.proxy(this.handleToggleNav, this));
	},

	/**
	 *  Remove 'no-js' class from html tag
	 *
	 *	@param {object} el - element to apply method to
	 */
	removeJSIdentifier : function(el) {
		el.removeClass("no-js");

		if ( !!document.createElementNS && !!document.createElementNS("http://www.w3.org/2000/svg", "svg").createSVGRect ) {
			el.addClass("supports-svg");
		}
	},

	/**
	 * 	If a dropdown overlaps the edge of the screen, add the class 'edge' to it
	 *
	 *	@param {string} el
	 */
	checkDropdownPos : function(el) {
		var $dropdown = (typeof el !== "object" || !el.length) ? $("ul:not(.mobile-nav) ul", this.el.navMenu) : el;

		if(!$dropdown.length) return;

		var	offset = $dropdown.offset(),
			l = offset.left,
			w = $dropdown.width(),
			docW = $(window).width(),
			hidden = false,
			isEntirelyVisible = (l+w <= docW);

		if($dropdown.is(":hidden")) {
			hidden = true;
			$dropdown.show();
		}

		if (! isEntirelyVisible) {
			$dropdown.addClass("edge");
		}

		if(hidden) {
			$dropdown.removeAttr("style");
		}
	},

	/**
	 *	Initialize sticky header
	 *
	 *	@param {object} el - element to apply method to
	 */
	initStickyHeader : function(el) {
		if(!el.length) return;

		var scrollPosCheck = el.offset().top + (el.height()+50);
		var debounceTimeout = false;

		$(window).on("load scroll", function() {
			clearTimeout(debounceTimeout);

			debounceTimeout = setTimeout(function() {
				if ($(window).scrollTop() > scrollPosCheck) {
					el.addClass("sticky");
				} else {
					el.removeClass("sticky");
				}
			},10);
		});
	},

	/**
	 *	Initialize Dropdown menus
	 *
	 *	@param {object} el - element to apply method to
	 *	@requires $.fn.hoverIntent
	 */
	initDropdowns : function(el) {
		var self = this;

		if(!el.length) return;

		self.checkDropdownPos($("> ul, > .dropdown", el));

		if (typeof hoverintent === "function") {
			el.each(function() {
				hoverintent(
					this,
					function() {
						var $dropdown = $("> ul, > .dropdown", $(this));
						if($dropdown.length) {
							self.checkDropdownPos($($dropdown[0]));
							$(this).addClass("active");
						}
					},
					function() {
						var $dropdown = $("> ul, > .dropdown", $(this));
						if($dropdown.length) {
							$(this).removeClass("active");
							setTimeout(function() {$dropdown.removeClass("edge");}, 200);
						}
					}
				).options({
					sensitivity: 1,
					interval: 100,
					timeout: 250,
				});
			});
		} else {
			throw("Missing hoverIntent plugin");
		}
	},

	/**
	 *	Toggle the mobile nav menu
	 *
	 * 	@event App#onNavToggle:click
	 *	@param {object} e - event object
	 */
	handleToggleNav : function(e) {
		var $page = this.el.body,
			$el = $("#page-header").add($("#mobile-nav"));

		e.preventDefault();

		$page.removeClass("drawer-open");

		if(! $page.hasClass("nav-open")) {
			$page.addClass("nav-open");

			$(document).on("page:before-change keydown.navdrop.odc click.navdrop.odc", function(e) {
				if(typeof e.key !== "undefined") {
					if(e.key === "Escape") {
						$page.removeClass("nav-open");
						$(document).off(".navdrop");
					}
				} else if(! $(e.target).parents().is($el) && ! $(e.target).is($el)) {
					$page.removeClass("nav-open");
					$(document).off(".navdrop.odc");
				}
			});
		} else {
			$page.removeClass("nav-open");
			$(document).off(".navdrop.odc");
		}
	},

	/**
	 *	Toggle the mobile nav dropdown
	 *
	 * 	@event App#handleMobileNavDropdown:click
	 *	@param {object} e - event object
	 */
	handleMobileNavDropdown : function(e) {
		var $dropdown = $(e.target).closest(".dropdown");

		e.preventDefault();

		if($dropdown.hasClass("open")) {
			$dropdown.removeClass("open");
		} else {
			$dropdown.addClass("open");
		}
	}

};

// Fire methods
return App.init();
}(window, jQuery));