/*global jQuery */
(function(w,$) {
"use strict";

/**
 * Plugins
 *
 * Initializes jquery plugins
 *
 * @requires jQuery
 */
var Plugins = {

	/**
	 * 	Store DOM elements
	 */
	getEl : function() {
		return {
			modalLinks : $("a[href$='jpg'], a[href$='png'], a[href$='gif']"),
			slider : $("[data-slider]")
		};
	},

	/**
	 * 	Initialize methods
	 */
	init : function() {
		try{
			this.el = this.getEl();
			// this.modal(this.el.modalLinks);
			this.slider(this.el.slider);
		} catch(exception) {
			console.warn(exception);
		}
	},

	/**
	 *	initialize modal
	 *
	 *	@param object el - element to apply plugin to
	 *	@requires $.fn.swipebox
	 */
	modal : function(el) {
		if ($.fn.swipebox) {
			return el.swipebox();
		} else {
			throw("Missing Swipebox plugin");
		}
	},

	/**
	 *	initialize slider
	 *
	 *	@param object el - element to apply plugin to
	 *	@requires $.fn.slick
	 */
	slider : function(el) {
		if($.fn.slick) {
			if(!el.length) return;

			el.each(function() {
				$(this).slick({
					pauseOnHover: $(this).data("slider-auto") ? true : false,
					infinite: $(this).data("slider-infinite") ? true : false,
					autoplay: $(this).data("slider-auto") ? true : false,
					slidesToScroll: $(this).data("slides") || 1,
					slidesToShow: $(this).data("slides") || 1,
					autoplaySpeed: 6000,
					swipeToSlide: false,
					touchMove: false,
					arrows: true,
					prevArrow: "<button type=\"button\" class=\"slick-prev\"></button>",
					nextArrow: "<button type=\"button\" class=\"slick-next\"></button>",
					// responsive: [
					// 	{
					// 		breakpoint: 600,
					// 		settings: {
					// 			slidesToShow: $(this).data("slides") < 2 && $(this).data("slides") || 1,
					// 			slidesToScroll: $(this).data("slides") < 2 && $(this).data("slides") || 1
					// 		}
					// 	},
					// 	{
					// 		breakpoint: 480,
					// 		settings: {
					// 			slidesToShow: 1,
					// 			slidesToScroll: 1
					// 		}
					// 	}
					// ]
				});
			});
		} else {
			console.warn("Missing Slider plugin");
		}
	}

};

// Fire methods
return Plugins.init();
}(window, jQuery));